import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/login"
  },

  {
    path: '/login',
    name: 'login',
    component:  () => import('../views/login/LoginView.vue')
  },
  {  
    path: '/user',  
    name: 'User',  
    component:  () => import('../views/UserView.vue'),  
  },  
  {  
    path: '/merchant',  
    name: 'Merchant',  
    component:  () => import('../views/login/MerchantView.vue'),  
  },  



 

  {  
    path: '/userin',  
    name: 'Userin',  
    component:  () => import('../views/NavigationView/UserInformation.vue'),  
  }, 
  {  
    path: '/order',  
    name: 'Order',  
    component:  () => import('../views/NavigationView/Orders.vue'),  
  }, 
  {  
    path: '/recharge',  
    name: 'Recharge',  
    component:  () => import('../views/NavigationView/Recharge.vue'),  
  }, 
  {  
    path: '/shopcar',  
    name: 'Shop',  
    component:  () => import('../views/NavigationView/ShoppingCart.vue'),  
  }, 
  {  
    path: '/sregister',  
    name: 'refister',  
    component:  () => import('../views/login/Sregister.vue'),  
  }, 
  {  
    path: '/prearea',  
    name: 'prearea',  
    component:  () => import('../views/PreArea.vue'),  
  }, 
  {  
    path: '/commodity',  
    name: 'commodity',  
    component:  () => import('../views/CommodityView.vue'),  
  }, 
  {  
    path: '/OrdersView',  
    name: 'OrderView',  
    component:  () => import('../views/login/OrdersView.vue'),  
  }, 
  {  
    path: '/Usersee',  
    name: 'Usersee',  
    component:  () => import('../views/UserseeView.vue'),  
  }, 
  {  
    path: '/orders',  
    name: 'orders',  
    component:  () => import('../views/login/OrderView.vue'),  
  }, 
  {  
    path: '/Bussiness',  
    name: 'bussiness',  
    component:  () => import('../views/login/BussinessView.vue'),  
  }, 
  {  
    path: '/goodView',  
    name: 'good',  
    component:  () => import('../views/login/GoodView.vue'),  
  }, 
  {  
    path: '/manageView',  
    name: 'manage',  
    component:  () => import('../views/login/ManageView.vue'),  
  }, 
  
  
]


const router = new VueRouter({
  routes
})

export default router
